"use client";
import { ReactNode, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "@/lib/states/globalStates";
import { PersistentHeader } from "@/components/persistentHeaderBar/persistentHeader";
import { NavMenuTop } from "@/components/GlobalNavMenu/NavMenuTop";
import { NavMenuBottom } from "@/components/GlobalNavMenu/NavMenuBottom";
import { TooltipProvider } from "@/components/ui/tooltip";
import { EMRPhysician } from "@/lib/types/physician";
import { useSession } from "next-auth/react";
import { CreateTaskNote } from "@/components/taskNotes/createTaskNote";
import { NavigationGuardProvider } from "next-navigation-guard";

interface LayoutProps {
  children: ReactNode;
  userData: EMRPhysician;
}

export const MainLayout = ({ children, userData }: LayoutProps) => {
  const [, setUser] = useRecoilState(userAtom);
  const [notesOpen, setNotesOpen] = useState(false);
  const { data: session } = useSession();

  useEffect(() => {
    if (Object.keys(userData ?? {}).length > 0) {
      setUser(userData);
    }
  }, [userData]);

  return (
    <TooltipProvider delayDuration={300}>
      <NavigationGuardProvider>
        <div className="flex min-h-screen">
          <div className="flex h-screen w-14 flex-col justify-between bg-primary-fg-blue [@media(min-height:700px)]:w-[3.75rem] [@media(min-height:850px)]:w-20">
            <NavMenuTop role={session!.user.role} />
            <NavMenuBottom
              role={session!.user.role}
              setNotesOpen={setNotesOpen}
            />
          </div>

          <div className="flex h-screen min-h-0 min-w-0 flex-1 flex-col bg-[#f0f2f5]">
            <PersistentHeader
              user={userData}
              setNotesOpen={setNotesOpen}
            />
            {children}
            <CreateTaskNote
              isOpen={notesOpen}
              setOpen={setNotesOpen}
            />
          </div>
        </div>
      </NavigationGuardProvider>
    </TooltipProvider>
  );
};
